import React, { useEffect, useRef } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import styles from './search.less';
import { Input, Space, Tag } from 'antd';
import { useHover } from 'react-use';
import { useSelector } from 'react-redux';
import authSelectors from 'src/modules/auth/authSelectors';

export interface SearchProps {
  docsearchOptions?: {
    apiKey: string;
    indexName: string;
  };
}

function initDocSearch({
  docsearch,
}: {
  docsearch: any;
}) {
        if (!docsearch) {
        return;
      }
      
      docsearch({
        apiKey: 'fd0bb4bc99f2a2c8be59a43070083b24',
        appId: '4J3QEDMHZS', 
        indexName: 'dev_website',
        inputSelector: `#searchBox`,
        transformData(
          hits: {
            url: string;
          }[],
        ) {
          hits.forEach((hit) => {
            // eslint-disable-next-line  no-param-reassign
            hit.url = hit.url.replace('sortd.io', window.location.host);
            // eslint-disable-next-line  no-param-reassign
            hit.url = hit.url.replace('https:', window.location.protocol);
          });
          return hits;
        },
        debug: false, // Set debug to true if you want to inspect the dropdown
      });
}


const Search: React.FC<SearchProps> = () => {
  const inputRef = useRef(null)
  const currentTenant = useSelector(
    authSelectors.selectCurrentTenant,
  );
  const searchBox = () => <input id='searchBox' ref={inputRef} className={styles.input} placeholder={(`Search Workspace...`)} />;

  const [hoverRef, hovered] = useHover(searchBox);

  const handleKeyDown = (event) => {
    if(event.keyCode === 32 && event.ctrlKey){
      if (inputRef?.current) {
        (inputRef?.current as any).focus();
      }
    }
  };
  useEffect(() => {
    window.addEventListener('keyup', handleKeyDown);

    // cleanup this component
    return () => {
      window.removeEventListener('keyup', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      import('docsearch.js').then(({ default: docsearch }) => {
        initDocSearch({
          docsearch,
        });
      });
    }
  }, []);
  return (
    <label className={styles.search} htmlFor="search">
      <SearchOutlined className={styles.icon} />
      {hoverRef}
      <Space><Tag visible={hovered} style={{opacity: 0.5}}>CTRL</Tag><Tag visible={hovered} style={{opacity: 0.5}}>SPACEBAR</Tag></Space>
    </label>
  );
};

export default Search;
