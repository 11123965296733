import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import initializers from 'src/modules/initializers';
import createRootReducer from 'src/modules/reducers';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunkMiddleware from 'redux-thunk';
import createSocketIoMiddleware from 'redux-socket.io';
import io from 'socket.io-client';
import config from 'src/config';


function optimisticExecute(action, emit, next, dispatch) {
  emit('action', action);
  next(action);
}

const socket = io(config.baseUrl);
const socketIoMiddleware = createSocketIoMiddleware(socket, 'server/', { execute: optimisticExecute });

const history = createBrowserHistory();

let store;


export function configureStore(preloadedState?) {
  const middlewares = [
    socketIoMiddleware,
    thunkMiddleware,
    routerMiddleware(history),
  ].filter(Boolean);

  store = createStore(
    createRootReducer(history),
    preloadedState,
    composeWithDevTools(applyMiddleware(...middlewares)),
  );

  for (const initializer of initializers) {
    initializer(store);
  }

  return store;
}

export function getHistory() {
  return history;
}

export default function getStore() {
  return store;
}

