import { connectRouter } from 'connected-react-router';
import layout from 'src/modules/layout/layoutReducers';
import auth from 'src/modules/auth/authReducers';
import tenant from 'src/modules/tenant/tenantReducers';
import user from 'src/modules/user/userReducers';
import auditLog from 'src/modules/auditLog/auditLogReducers';
import settings from 'src/modules/settings/settingsReducers';
import project from 'src/modules/project/projectReducers';
import requirement from 'src/modules/requirement/requirementReducers';
import workshops from 'src/modules/workshops/workshopsReducers';
import responses from 'src/modules/responses/responsesReducers';
import businessUnits from 'src/modules/businessUnits/businessUnitsReducers';
import acceptanceCriteria from 'src/modules/acceptanceCriteria/acceptanceCriteriaReducers';
import tags from 'src/modules/tags/tagsReducers';
import { combineReducers } from 'redux';
import plan from 'src/modules/plan/planReducers';
import socket from 'src/modules/socket/socketReducers';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    layout,
    auth,
    tenant,
    plan,
    user,
    auditLog,
    settings,
    project,
    requirement,
    workshops,
    responses,
    businessUnits,
    acceptanceCriteria,
    tags,
    socket
  });
