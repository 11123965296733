import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/acceptanceCriteria/importer/acceptanceCriteriaImporterSelectors';
import AcceptanceCriteriaService from 'src/modules/acceptanceCriteria/acceptanceCriteriaService';
import fields from 'src/modules/acceptanceCriteria/importer/acceptanceCriteriaImporterFields';
import { i18n } from 'src/i18n';

const acceptanceCriteriaImporterActions = importerActions(
  'ACCEPTANCECRITERIA_IMPORTER',
  selectors,
  AcceptanceCriteriaService.import,
  fields,
  i18n('entities.acceptanceCriteria.importer.fileName'),
);

export default acceptanceCriteriaImporterActions;