import list from 'src/modules/requirement/list/requirementListReducers';
import form from 'src/modules/requirement/form/requirementFormReducers';
import view from 'src/modules/requirement/view/requirementViewReducers';
import destroy from 'src/modules/requirement/destroy/requirementDestroyReducers';
import importerReducer from 'src/modules/requirement/importer/requirementImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
