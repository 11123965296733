import list from 'src/modules/acceptanceCriteria/list/acceptanceCriteriaListReducers';
import form from 'src/modules/acceptanceCriteria/form/acceptanceCriteriaFormReducers';
import view from 'src/modules/acceptanceCriteria/view/acceptanceCriteriaViewReducers';
import destroy from 'src/modules/acceptanceCriteria/destroy/acceptanceCriteriaDestroyReducers';
import importerReducer from 'src/modules/acceptanceCriteria/importer/acceptanceCriteriaImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
