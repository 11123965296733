import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'name',
    label: i18n('entities.workshops.fields.name'),
    schema: schemas.string(
      i18n('entities.workshops.fields.name'),
      {},
    ),
  },
  {
    name: 'requirements',
    label: i18n('entities.workshops.fields.requirements'),
    schema: schemas.relationToMany(
      i18n('entities.workshops.fields.requirements'),
      {},
    ),
  },
  {
    name: 'status',
    label: i18n('entities.workshops.fields.status'),
    schema: schemas.integer(
      i18n('entities.workshops.fields.status'),
      {},
    ),
  },
  {
    name: 'responses',
    label: i18n('entities.workshops.fields.responses'),
    schema: schemas.relationToMany(
      i18n('entities.workshops.fields.responses'),
      {},
    ),
  },
  {
    name: 'project',
    label: i18n('entities.workshops.fields.project'),
    schema: schemas.relationToOne(
      i18n('entities.workshops.fields.project'),
      {},
    ),
  },
  {
    name: 'businessUnits',
    label: i18n('entities.workshops.fields.businessUnits'),
    schema: schemas.relationToMany(
      i18n('entities.workshops.fields.businessUnits'),
      {},
    ),
  },
  {
    name: 'timedRequirements',
    label: i18n('entities.workshops.fields.timedRequirements'),
    schema: schemas.boolean(
      i18n('entities.workshops.fields.timedRequirements'),
      {},
    ),
  },
  {
    name: 'timePerRequirement',
    label: i18n('entities.workshops.fields.timePerRequirement'),
    schema: schemas.integer(
      i18n('entities.workshops.fields.timePerRequirement'),
      {},
    ),
  },
  {
    name: 'allowRequirementComments',
    label: i18n('entities.workshops.fields.allowRequirementComments'),
    schema: schemas.boolean(
      i18n('entities.workshops.fields.allowRequirementComments'),
      {},
    ),
  },
  {
    name: 'allowFinalComments',
    label: i18n('entities.workshops.fields.allowFinalComments'),
    schema: schemas.boolean(
      i18n('entities.workshops.fields.allowFinalComments'),
      {},
    ),
  },
];