import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/responses/importer/responsesImporterSelectors';
import ResponsesService from 'src/modules/responses/responsesService';
import fields from 'src/modules/responses/importer/responsesImporterFields';
import { i18n } from 'src/i18n';

const responsesImporterActions = importerActions(
  'RESPONSES_IMPORTER',
  selectors,
  ResponsesService.import,
  fields,
  i18n('entities.responses.importer.fileName'),
);

export default responsesImporterActions;