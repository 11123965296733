import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.project.fields.id'),
  },
  {
    name: 'name',
    label: i18n('entities.project.fields.name'),
  },
  {
    name: 'requirements',
    label: i18n('entities.project.fields.requirements'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'lead',
    label: i18n('entities.project.fields.lead'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'team',
    label: i18n('entities.project.fields.team'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'workshops',
    label: i18n('entities.project.fields.workshops'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'responses',
    label: i18n('entities.project.fields.responses'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'businessUnits',
    label: i18n('entities.project.fields.businessUnits'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'tags',
    label: i18n('entities.project.fields.tags'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'status',
    label: i18n('entities.project.fields.status'),
  },
  {
    name: 'key',
    label: i18n('entities.project.fields.key'),
  },
  {
    name: 'shared',
    label: i18n('entities.project.fields.shared'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'description',
    label: i18n('entities.project.fields.description'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.project.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.project.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
