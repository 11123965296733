import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'name',
    label: i18n('entities.requirement.fields.name'),
    schema: schemas.string(
      i18n('entities.requirement.fields.name'),
      {},
    ),
  },
  {
    name: 'project',
    label: i18n('entities.requirement.fields.project'),
    schema: schemas.relationToOne(
      i18n('entities.requirement.fields.project'),
      {},
    ),
  },
  {
    name: 'lead',
    label: i18n('entities.requirement.fields.lead'),
    schema: schemas.relationToOne(
      i18n('entities.requirement.fields.lead'),
      {},
    ),
  },
  {
    name: 'workshops',
    label: i18n('entities.requirement.fields.workshops'),
    schema: schemas.relationToMany(
      i18n('entities.requirement.fields.workshops'),
      {},
    ),
  },
  {
    name: 'responses',
    label: i18n('entities.requirement.fields.responses'),
    schema: schemas.relationToMany(
      i18n('entities.requirement.fields.responses'),
      {},
    ),
  },
  {
    name: 'status',
    label: i18n('entities.requirement.fields.status'),
    schema: schemas.integer(
      i18n('entities.requirement.fields.status'),
      {},
    ),
  },
  {
    name: 'rating',
    label: i18n('entities.requirement.fields.rating'),
    schema: schemas.integer(
      i18n('entities.requirement.fields.rating'),
      {},
    ),
  },
  {
    name: 'uniqueId',
    label: i18n('entities.requirement.fields.uniqueId'),
    schema: schemas.string(
      i18n('entities.requirement.fields.uniqueId'),
      {},
    ),
  },
  {
    name: 'acceptanceCriteria',
    label: i18n('entities.requirement.fields.acceptanceCriteria'),
    schema: schemas.relationToMany(
      i18n('entities.requirement.fields.acceptanceCriteria'),
      {},
    ),
  },
  {
    name: 'priority',
    label: i18n('entities.requirement.fields.priority'),
    schema: schemas.integer(
      i18n('entities.requirement.fields.priority'),
      {},
    ),
  },
  {
    name: 'resolvedRating',
    label: i18n('entities.requirement.fields.resolvedRating'),
    schema: schemas.integer(
      i18n('entities.requirement.fields.resolvedRating'),
      {},
    ),
  },
];