import React from 'react';
import {Avatar as AntAvatar} from 'antd';
import gradient from 'random-gradient'
import { generateKeyNoNumeric } from 'src/modules/shared/utils/strings';

interface IAvatar {
    src?: string;
    name?: string;
    keyName?: string;
    id: string;
    children?: any
    size?: 'small' | 'default' | 'large'
    shape?: 'circle' | 'square';
}
const Avatar = ({src, name, keyName, id, children, size, shape}: IAvatar) => {
    const bgGradient = { background: gradient(id) }

    return (
        <AntAvatar shape={shape} size={size} style={!src ? bgGradient : undefined} src={src}>
            {children || (!src && (keyName || (name && generateKeyNoNumeric(name))))}
        </AntAvatar>

    )
}

export default Avatar