import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/requirement/importer/requirementImporterSelectors';
import RequirementService from 'src/modules/requirement/requirementService';
import fields from 'src/modules/requirement/importer/requirementImporterFields';
import { i18n } from 'src/i18n';

const requirementImporterActions = importerActions(
  'REQUIREMENT_IMPORTER',
  selectors,
  RequirementService.import,
  fields,
  i18n('entities.requirement.importer.fileName'),
);

export default requirementImporterActions;