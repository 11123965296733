import {
    LockOutlined,
    LogoutOutlined,
    AppstoreOutlined,
    UserOutlined,
    CodeOutlined,
    SettingOutlined,
  } from '@ant-design/icons';
  import { Avatar, Menu } from 'antd';
  import React from 'react';
  import { useSelector, useDispatch } from 'react-redux';
  import { i18n } from 'src/i18n';
  import authActions from 'src/modules/auth/authActions';
  import authSelectors from 'src/modules/auth/authSelectors';
  import { getHistory } from 'src/modules/store';
  import config from 'src/config';
  import Permissions from 'src/security/permissions';
  import PermissionChecker from 'src/modules/auth/permissionChecker';
  import styles from '../index.less';
import HeaderDropdown from '../HeaderDropdown';
  
  
  
  const UserMenuAvatar = (props) => {
    const dispatch = useDispatch();
    const currentTenant = useSelector(
      authSelectors.selectCurrentTenant,
    );
    const currentUser = useSelector(
      authSelectors.selectCurrentUser,
    );
    const userDropdownText = useSelector(
      authSelectors.selectCurrentUserNameOrEmailPrefix,
    );
    const userDropdownAvatar = useSelector(
      authSelectors.selectCurrentUserAvatar,
    );
  
    const doSignout = () => {
      dispatch(authActions.doSignout());
    };
  
    const doNavigateToProfile = () => {
      getHistory().push('/profile');
    };
  
    const doNavigateToPasswordChange = () => {
      getHistory().push('/password-change');
    };
  
    const doNavigateToTenants = () => {
      getHistory().push('/tenant');
    };
  
    const doNavigateToSettings = () => {
      getHistory().push('/settings');
    };
  
  
    const permissions = Permissions.values;
  
    const permissionChecker = new PermissionChecker(
      currentTenant,
      currentUser,
    );
  
    const userMenu = (
      <Menu selectedKeys={[]}>
        <Menu.Item
          onClick={doNavigateToProfile}
          key="userCenter"
        >
          <UserOutlined />
          {i18n('auth.profile.title')}
        </Menu.Item>
        <Menu.Item
          onClick={doNavigateToPasswordChange}
          key="passwordChange"
        >
          <LockOutlined />
          {i18n('auth.passwordChange.title')}
        </Menu.Item>
        {['multi', 'multi-with-subdomain'].includes(
          config.tenantMode,
        ) && (
          <Menu.Item
            onClick={doNavigateToTenants}
            key="tenants"
          >
            <AppstoreOutlined />
            {i18n('auth.tenants')}
          </Menu.Item>
        )}
        {['multi', 'multi-with-subdomain'].includes(
          config.tenantMode,
        ) && (
          permissionChecker.match(permissions.settingsEdit) ||
          permissionChecker.match(permissions.planRead) ||
          permissionChecker.match(permissions.auditLogRead) ||
          permissionChecker.match(permissions.userRead)
        ) && (
          <Menu.Item
            onClick={doNavigateToSettings}
            key="settings"
          >
            <SettingOutlined />
            {i18n('auth.settings')}
          </Menu.Item>
        )}
        {config.apiDocumentationUrl && (
          <Menu.Item key="api">
            <a
              href={config.apiDocumentationUrl}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
            >
              <CodeOutlined style={{ marginRight: 8 }} />
              {i18n('api.menu')}
            </a>
          </Menu.Item>
        )}
        <Menu.Divider />
        <Menu.Item onClick={doSignout} key="logout">
          <LogoutOutlined />
          {i18n('auth.signout')}
        </Menu.Item>
      </Menu>
    );
  
    return (
            <HeaderDropdown overlay={userMenu}>
            <span className={`${styles.action} ${styles.account}`}>
            <Avatar
                size="small"
                src={userDropdownAvatar || undefined}
                alt="avatar"
                className={styles.avatar}
                icon={
                userDropdownAvatar ? undefined : (
                    <UserOutlined />
                )
                }
            />
              <span className={`${styles.name} anticon`}>
                {userDropdownText}
              </span>
            </span>
          </HeaderDropdown>
    );
  };
  
  export default UserMenuAvatar;
  