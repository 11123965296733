import React from 'react'
import logo from 'src/assets/images/shared/logo_black.png';
import styled from 'styled-components';
import { Spin } from 'antd';

const Wrapper = styled.div`
    height: 100vh;
    background-image: url('/home_bg.png');
    margin: 0;
    padding: 0;
    background-repeat: no-repeat;
    background-size: 100% auto;
`

const OuterWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    min-height: 420px;
`
const InnerWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 98px;
`

export default() => (
    <Wrapper>
        <OuterWrap>
            <img src={logo} alt="logo" width="128px" />
            <InnerWrap>
            <Spin spinning size='large' />
            </InnerWrap>
          </OuterWrap>
    </Wrapper>
)