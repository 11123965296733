import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.requirement.fields.id'),
  },
  {
    name: 'name',
    label: i18n('entities.requirement.fields.name'),
  },
  {
    name: 'project',
    label: i18n('entities.requirement.fields.project'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'lead',
    label: i18n('entities.requirement.fields.lead'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'workshops',
    label: i18n('entities.requirement.fields.workshops'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'responses',
    label: i18n('entities.requirement.fields.responses'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'status',
    label: i18n('entities.requirement.fields.status'),
  },
  {
    name: 'rating',
    label: i18n('entities.requirement.fields.rating'),
  },
  {
    name: 'uniqueId',
    label: i18n('entities.requirement.fields.uniqueId'),
  },
  {
    name: 'acceptanceCriteria',
    label: i18n('entities.requirement.fields.acceptanceCriteria'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'priority',
    label: i18n('entities.requirement.fields.priority'),
  },
  {
    name: 'resolvedRating',
    label: i18n('entities.requirement.fields.resolvedRating'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.requirement.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.requirement.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
