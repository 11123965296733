import Permissions from 'src/security/permissions';

const permissions = Permissions.values;

const privateRoutes = [
  {
    path: '/',
    loader: () =>
      import('src/view/dashboard/DashboardPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/profile',
    loader: () => import('src/view/auth/ProfileFormPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/password-change',
    loader: () =>
      import('src/view/auth/PasswordChangeFormPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/tenant',
    loader: () =>
      import('src/view/tenant/list/TenantListPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/tenant/new',
    loader: () =>
      import('src/view/tenant/form/TenantFormPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/tenant/:id/edit',
    loader: () =>
      import('src/view/tenant/form/TenantFormPage'),
    permissionRequired: null,
    exact: true,
  },




  {
    path: '/user/new',
    loader: () => import('src/view/user/new/UserNewPage'),
    permissionRequired: permissions.userCreate,
    exact: true,
  },

  {
    path: '/user/importer',
    loader: () =>
      import('src/view/user/importer/UserImporterPage'),
    permissionRequired: permissions.userImport,
    exact: true,
  },
  {
    path: '/user/:id/edit',
    loader: () => import('src/view/user/edit/UserEditPage'),
    permissionRequired: permissions.userEdit,
    exact: true,
  },
  {
    path: '/user/:id',
    loader: () => import('src/view/user/view/UserViewPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },



  {
    path: '/settings',
    loader: () => import('src/view/settings/SettingsWrapper'),
    permissionRequired: permissions.settingsEdit,
    exact: false,
  },
  

  {
    path: '/project',
    loader: () =>
      import('src/view/project/list/ProjectListPage'),
    permissionRequired: permissions.projectRead,
    exact: true,
  },
  {
    path: '/project/new',
    loader: () =>
      import('src/view/project/form/ProjectFormPage'),
    permissionRequired: permissions.projectCreate,
    exact: true,
  },
  {
    path: '/project/importer',
    loader: () =>
      import(
        'src/view/project/importer/ProjectImporterPage'
      ),
    permissionRequired: permissions.projectImport,
    exact: true,
  },
  {
    path: '/project/:id/edit',
    loader: () =>
      import('src/view/project/form/ProjectFormPage'),
    permissionRequired: permissions.projectEdit,
    exact: true,
  },
  {
    path: '/project/:id/settings',
    loader: () =>
      import('src/view/project/settings/ProjectSettingsPage'),
    permissionRequired: permissions.projectEdit,
    exact: true,
  },
  {
    path: '/project/:id',
    loader: () =>
      import('src/view/project/view/ProjectViewPage'),
    permissionRequired: permissions.projectRead,
    exact: true,
  },

  {
    path: '/requirement',
    loader: () =>
      import('src/view/requirement/list/RequirementListPage'),
    permissionRequired: permissions.requirementRead,
    exact: true,
  },
  {
    path: '/requirement/new',
    loader: () =>
      import('src/view/requirement/form/RequirementFormPage'),
    permissionRequired: permissions.requirementCreate,
    exact: true,
  },
  {
    path: '/requirement/importer',
    loader: () =>
      import(
        'src/view/requirement/importer/RequirementImporterPage'
      ),
    permissionRequired: permissions.requirementImport,
    exact: true,
  },
  {
    path: '/requirement/:id/edit',
    loader: () =>
      import('src/view/requirement/form/RequirementFormPage'),
    permissionRequired: permissions.requirementEdit,
    exact: true,
  },
  {
    path: '/requirement/:id',
    loader: () =>
      import('src/view/requirement/view/RequirementViewPage'),
    permissionRequired: permissions.requirementRead,
    exact: true,
  },

  {
    path: '/workshops',
    loader: () =>
      import('src/view/workshops/list/WorkshopsListPage'),
    permissionRequired: permissions.workshopsRead,
    exact: true,
  },
  {
    path: '/workshops/new',
    loader: () =>
      import('src/view/workshops/form/WorkshopsFormPage'),
    permissionRequired: permissions.workshopsCreate,
    exact: true,
  },
  {
    path: '/workshops/importer',
    loader: () =>
      import(
        'src/view/workshops/importer/WorkshopsImporterPage'
      ),
    permissionRequired: permissions.workshopsImport,
    exact: true,
  },
  {
    path: '/workshops/:id/edit',
    loader: () =>
      import('src/view/workshops/form/WorkshopsFormPage'),
    permissionRequired: permissions.workshopsEdit,
    exact: true,
  },
  {
    path: '/workshops/:id',
    loader: () =>
      import('src/view/workshops/view/WorkshopsViewPage'),
    permissionRequired: permissions.workshopsRead,
    exact: true,
  },

  {
    path: '/responses',
    loader: () =>
      import('src/view/responses/list/ResponsesListPage'),
    permissionRequired: permissions.responsesRead,
    exact: true,
  },
  {
    path: '/responses/new',
    loader: () =>
      import('src/view/responses/form/ResponsesFormPage'),
    permissionRequired: permissions.responsesCreate,
    exact: true,
  },
  {
    path: '/responses/importer',
    loader: () =>
      import(
        'src/view/responses/importer/ResponsesImporterPage'
      ),
    permissionRequired: permissions.responsesImport,
    exact: true,
  },
  {
    path: '/responses/:id/edit',
    loader: () =>
      import('src/view/responses/form/ResponsesFormPage'),
    permissionRequired: permissions.responsesEdit,
    exact: true,
  },
  {
    path: '/responses/:id',
    loader: () =>
      import('src/view/responses/view/ResponsesViewPage'),
    permissionRequired: permissions.responsesRead,
    exact: true,
  },

  {
    path: '/business-units',
    loader: () =>
      import('src/view/businessUnits/list/BusinessUnitsListPage'),
    permissionRequired: permissions.businessUnitsRead,
    exact: true,
  },
  {
    path: '/business-units/new',
    loader: () =>
      import('src/view/businessUnits/form/BusinessUnitsFormPage'),
    permissionRequired: permissions.businessUnitsCreate,
    exact: true,
  },
  {
    path: '/business-units/importer',
    loader: () =>
      import(
        'src/view/businessUnits/importer/BusinessUnitsImporterPage'
      ),
    permissionRequired: permissions.businessUnitsImport,
    exact: true,
  },
  {
    path: '/business-units/:id/edit',
    loader: () =>
      import('src/view/businessUnits/form/BusinessUnitsFormPage'),
    permissionRequired: permissions.businessUnitsEdit,
    exact: true,
  },
  {
    path: '/business-units/:id',
    loader: () =>
      import('src/view/businessUnits/view/BusinessUnitsViewPage'),
    permissionRequired: permissions.businessUnitsRead,
    exact: true,
  },

  {
    path: '/acceptance-criteria',
    loader: () =>
      import('src/view/acceptanceCriteria/list/AcceptanceCriteriaListPage'),
    permissionRequired: permissions.acceptanceCriteriaRead,
    exact: true,
  },
  {
    path: '/acceptance-criteria/new',
    loader: () =>
      import('src/view/acceptanceCriteria/form/AcceptanceCriteriaFormPage'),
    permissionRequired: permissions.acceptanceCriteriaCreate,
    exact: true,
  },
  {
    path: '/acceptance-criteria/importer',
    loader: () =>
      import(
        'src/view/acceptanceCriteria/importer/AcceptanceCriteriaImporterPage'
      ),
    permissionRequired: permissions.acceptanceCriteriaImport,
    exact: true,
  },
  {
    path: '/acceptance-criteria/:id/edit',
    loader: () =>
      import('src/view/acceptanceCriteria/form/AcceptanceCriteriaFormPage'),
    permissionRequired: permissions.acceptanceCriteriaEdit,
    exact: true,
  },
  {
    path: '/acceptance-criteria/:id',
    loader: () =>
      import('src/view/acceptanceCriteria/view/AcceptanceCriteriaViewPage'),
    permissionRequired: permissions.acceptanceCriteriaRead,
    exact: true,
  },

  {
    path: '/tags',
    loader: () =>
      import('src/view/tags/list/TagsListPage'),
    permissionRequired: permissions.tagsRead,
    exact: true,
  },
  {
    path: '/tags/new',
    loader: () =>
      import('src/view/tags/form/TagsFormPage'),
    permissionRequired: permissions.tagsCreate,
    exact: true,
  },
  {
    path: '/tags/importer',
    loader: () =>
      import(
        'src/view/tags/importer/TagsImporterPage'
      ),
    permissionRequired: permissions.tagsImport,
    exact: true,
  },
  {
    path: '/tags/:id/edit',
    loader: () =>
      import('src/view/tags/form/TagsFormPage'),
    permissionRequired: permissions.tagsEdit,
    exact: true,
  },
  {
    path: '/tags/:id',
    loader: () =>
      import('src/view/tags/view/TagsViewPage'),
    permissionRequired: permissions.tagsRead,
    exact: true,
  },
].filter(Boolean);

const publicRoutes = [
  {
    path: '/auth/signin',
    loader: () => import('src/view/auth/SigninPage'),
  },
  {
    path: '/auth/signup',
    loader: () => import('src/view/auth/SignupPage'),
  },
  {
    path: '/auth/forgot-password',
    loader: () =>
      import('src/view/auth/ForgotPasswordPage'),
  },
].filter(Boolean);

const emptyTenantRoutes = [
  {
    path: '/auth/tenant',
    loader: () => import('src/view/auth/TenantPage'),
  },
].filter(Boolean);

const emptyPermissionsRoutes = [
  {
    path: '/auth/empty-permissions',
    loader: () =>
      import('src/view/auth/EmptyPermissionsPage'),
  },
].filter(Boolean);

const emailUnverifiedRoutes = [
  {
    path: '/auth/email-unverified',
    loader: () =>
      import('src/view/auth/EmailUnverifiedPage'),
  },
].filter(Boolean);

const simpleRoutes = [
  {
    path: '/auth/password-reset',
    loader: () => import('src/view/auth/PasswordResetPage'),
  },
  {
    path: '/auth/invitation',
    loader: () => import('src/view/auth/InvitationPage'),
  },
  {
    path: '/auth/verify-email',
    loader: () => import('src/view/auth/VerifyEmailPage'),
  },
  {
    path: '/403',
    loader: () =>
      import('src/view/shared/errors/Error403Page'),
  },
  {
    path: '/500',
    loader: () =>
      import('src/view/shared/errors/Error500Page'),
  },
  {
    path: '**',
    loader: () =>
      import('src/view/shared/errors/Error404Page'),
  },
].filter(Boolean);

export default {
  privateRoutes,
  publicRoutes,
  emptyTenantRoutes,
  emptyPermissionsRoutes,
  emailUnverifiedRoutes,
  simpleRoutes,
};
