import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.responses.fields.id'),
  },
  {
    name: 'workshop',
    label: i18n('entities.responses.fields.workshop'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'requirement',
    label: i18n('entities.responses.fields.requirement'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'project',
    label: i18n('entities.responses.fields.project'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'score',
    label: i18n('entities.responses.fields.score'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.responses.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.responses.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
