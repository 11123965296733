import { PageLoading } from '@ant-design/pro-layout';
import React from 'react';
import LoadingComponent from 'src/view/shared/LoadingComponent';

export default function CustomLoadable(opts) {
  const LazyComponent = React.lazy(opts.loader);

  return (props) => (
    <React.Suspense fallback={opts.privateRoute? <PageLoading /> : <LoadingComponent />}>
      <LazyComponent {...props} />
    </React.Suspense>
  );
}
