import Errors from 'src/modules/shared/error/errors';
import Message from 'src/view/shared/message';

const prefix = 'SOCKET';

const socketActions = {
  ERROR_MESSAGE_CLEARED: `${prefix}_ERROR_MESSAGE_CLEARED`,

  SOCKET_INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  SOCKET_INIT_ERROR: `${prefix}_INIT_ERROR`,

  SAY_HELLO: `server/hello`,
  SAY_HELLO_SUCCESS: `${prefix}_HELLO_SUCCESS`,
  SAY_HELLO_ERROR: `${prefix}_HELLO_ERROR`,

  GET_HELLO_COUNT: `server/gethello`,
  GET_HELLO_COUNT_ERROR: `${prefix}_HELLO_COUNT_ERROR`,
  GET_HELLO_COUNT_SUCCESS: `${prefix}_HELLO_COUNT_SUCCESS`,
  GET_HELLO_COUNT_RECEIVE: `${prefix}_HELLO_COUNT_RECEIVE`,

  doClearErrorMessage() {
    return {
      type: socketActions.ERROR_MESSAGE_CLEARED,
    };
  },

  doSayHello: () => async (
    dispatch,
    getState,
  ) => {
    try {
      dispatch({ type: socketActions.SAY_HELLO });
      Message.success(
        "Said Hello",
      );
      dispatch({
        type: socketActions.SAY_HELLO_SUCCESS,
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({ type: socketActions.SAY_HELLO_ERROR });
    }
  },
  doFetch: () => async (dispatch) => {
    try {
        dispatch({
            type: socketActions.GET_HELLO_COUNT,
        });
    } catch (error) {
        dispatch({
            type: socketActions.GET_HELLO_COUNT_ERROR,
        });
    }
  },
  doInit: (store) => async (dispatch) => {
    try {
        store.subscribe(()=>{
        });
      dispatch({
        type: socketActions.SOCKET_INIT_SUCCESS,
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: socketActions.SOCKET_INIT_ERROR,
        payload: error,
      });
    }
  },

};

export default socketActions;
