import { GlobalOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import React from 'react';
import HeaderDropdown from '../HeaderDropdown';
import styles from './index.less';
import { useSelector } from 'react-redux';
import { getLanguages } from 'src/i18n';
import actions from 'src/modules/layout/layoutActions';
import selectors from 'src/modules/layout/layoutSelectors';

type SelectLangProps = {
  className?: string;
};

const SelectLang: React.FC<SelectLangProps> = (props) => {
  const { className } = props;

  const language = useSelector(selectors.selectLanguage);

  const langMenu = (
    <Menu className={styles.menu} selectedKeys={[language]} onClick={(language) => actions.doChangeLanguage(language.key)}>
      {getLanguages().map((locale) => (
        <Menu.Item key={locale.id}>
          <span role="img" aria-label={locale.label}>
            <img alt='flag' src={locale.flag} />
          </span>{' '}
          {locale.label}
        </Menu.Item>
      ))}
    </Menu>
  );
  return (
    <HeaderDropdown overlay={langMenu} placement="bottomRight">
      <span className={className}>
        <GlobalOutlined title="Language" />
      </span>
    </HeaderDropdown>
  );
};

export default SelectLang;