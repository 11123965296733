import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/businessUnits/importer/businessUnitsImporterSelectors';
import BusinessUnitsService from 'src/modules/businessUnits/businessUnitsService';
import fields from 'src/modules/businessUnits/importer/businessUnitsImporterFields';
import { i18n } from 'src/i18n';

const businessUnitsImporterActions = importerActions(
  'BUSINESSUNITS_IMPORTER',
  selectors,
  BusinessUnitsService.import,
  fields,
  i18n('entities.businessUnits.importer.fileName'),
);

export default businessUnitsImporterActions;