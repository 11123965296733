import {
  QuestionCircleOutlined
} from '@ant-design/icons';
import { Space, Tooltip } from 'antd';
import React from 'react';
import styles from './index.less';
import SelectLang from './SelectLang';
import UserMenuAvatar from './Avatar';

let className = styles.right;

const Header = (props) => {
  return (
    <Space className={className}>
          <Tooltip title="Help">
            <span
              className={styles.action}
              onClick={() => {
                window.open('https://preview.sortd.io/docs/requirements-pro', '_blank');
              }}
            >
              <QuestionCircleOutlined />
            </span>
          </Tooltip>
          <SelectLang className={styles.action} />
          <UserMenuAvatar />
    </Space>
  );
};

export default Header;
