import list from 'src/modules/businessUnits/list/businessUnitsListReducers';
import form from 'src/modules/businessUnits/form/businessUnitsFormReducers';
import view from 'src/modules/businessUnits/view/businessUnitsViewReducers';
import destroy from 'src/modules/businessUnits/destroy/businessUnitsDestroyReducers';
import importerReducer from 'src/modules/businessUnits/importer/businessUnitsImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
