import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.workshops.fields.id'),
  },
  {
    name: 'name',
    label: i18n('entities.workshops.fields.name'),
  },
  {
    name: 'requirements',
    label: i18n('entities.workshops.fields.requirements'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'status',
    label: i18n('entities.workshops.fields.status'),
  },
  {
    name: 'responses',
    label: i18n('entities.workshops.fields.responses'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'project',
    label: i18n('entities.workshops.fields.project'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'businessUnits',
    label: i18n('entities.workshops.fields.businessUnits'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'timedRequirements',
    label: i18n('entities.workshops.fields.timedRequirements'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'timePerRequirement',
    label: i18n('entities.workshops.fields.timePerRequirement'),
  },
  {
    name: 'allowRequirementComments',
    label: i18n('entities.workshops.fields.allowRequirementComments'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'allowFinalComments',
    label: i18n('entities.workshops.fields.allowFinalComments'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.workshops.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.workshops.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
