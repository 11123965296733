import list from 'src/modules/workshops/list/workshopsListReducers';
import form from 'src/modules/workshops/form/workshopsFormReducers';
import view from 'src/modules/workshops/view/workshopsViewReducers';
import destroy from 'src/modules/workshops/destroy/workshopsDestroyReducers';
import importerReducer from 'src/modules/workshops/importer/workshopsImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
