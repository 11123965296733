import Permissions from 'src/security/permissions';
import { i18n } from 'src/i18n';
import React from 'react';

import {
  DashboardOutlined,
  TeamOutlined,
  AimOutlined,
  ProjectOutlined
} from '@ant-design/icons';

const permissions = Permissions.values;

export default {
  route: {
    path: '/',
    routes: [
      {
        path: '/',
        exact: true,
        icon: <DashboardOutlined />,
        name: i18n('dashboard.menu'),
        permissionRequired: null,
      },

      {
        path: '/project',
        permissionRequired: permissions.projectRead,
        icon: <ProjectOutlined />,
        name: i18n('entities.project.menu'),
      },

      {
        path: '/requirement',
        permissionRequired: permissions.requirementRead,
        icon: <AimOutlined />,
        name: i18n('entities.requirement.menu'),
      },

      {
        path: '/workshops',
        permissionRequired: permissions.workshopsRead,
        icon: <TeamOutlined />,
        name: i18n('entities.workshops.menu'),
      },
    ].filter(Boolean)
  }
}
