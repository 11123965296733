import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/workshops/importer/workshopsImporterSelectors';
import WorkshopsService from 'src/modules/workshops/workshopsService';
import fields from 'src/modules/workshops/importer/workshopsImporterFields';
import { i18n } from 'src/i18n';

const workshopsImporterActions = importerActions(
  'WORKSHOPS_IMPORTER',
  selectors,
  WorkshopsService.import,
  fields,
  i18n('entities.workshops.importer.fileName'),
);

export default workshopsImporterActions;