import { notification } from 'antd';

const key = 'updateable'
export default class Message {
  static success(arg) {
    notification.success({
      message: arg,
      description: '',
      placement: 'bottomLeft',
    });
  }


  static countdownSuccess(arg) {
    let x = 1
    notification.success({
      key,
      message: arg,
      description: '',
      placement: 'bottomLeft',
    });
    const countdown = setInterval(() => {
      notification.success({
        key,
        message: arg.replace(/[0-9]/g, (arg.match(/[0-9]/g) as any) - x),
        placement: 'bottomLeft',
        description: '',
      });
      x++
      if(x === 3) clearInterval(countdown)
    }, 1000);
  }

  static error(arg) {
    notification.error({
      message: arg,
      description: '',
      placement: 'bottomLeft',
    });
  }
}
