import React from 'react';
import { PageContainer, ProSettings } from '@ant-design/pro-layout';
import ProLayout from '@ant-design/pro-layout';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import authSelectors from 'src/modules/auth/authSelectors';
import PermissionChecker from 'src/modules/auth/permissionChecker';
import menus from '../antdMenus';
import Header from 'src/view/layout/AntdHeader';
import layoutSelectors from 'src/modules/layout/layoutSelectors';
import Search from '../shared/components/Search';
import Avatar from 'src/view/shared/components/Avatar';

export default ({children}: any) => {
  const authLoading = useSelector(
    authSelectors.selectLoadingInit,
  );
  const layoutLoading = useSelector(
    layoutSelectors.selectLoading,
  );
  const loading = authLoading || layoutLoading;

  const settings: Partial<ProSettings> = ({ fixSiderbar: true, fixedHeader: true, layout: 'mix'});
  const pathname = useLocation().pathname;
  const currentTenant = useSelector(
    authSelectors.selectCurrentTenant,
  );
  const currentUser = useSelector(
    authSelectors.selectCurrentUser,
  );

  const logoUrl = useSelector(authSelectors.selectLogoUrl);

  const permissionChecker = new PermissionChecker(
    currentTenant,
    currentUser,
  );

  const matchMenu = (permission) => {
    return permissionChecker.match(permission);
  };

  return (
      <ProLayout
        title="Requirements Pro"
        logo={<Avatar shape={logoUrl ? 'square' : 'circle'} id={currentTenant?.id || 'Requirements Pro'} name={currentTenant?.name} src={logoUrl} />}
        locale='en-US'
        siderWidth={200}
        style={{minHeight: '100vh'}}
        loading={loading}
        headerContentRender={() => <Search />}
        {...menus}
        location={{
          pathname,
        }}
        menuItemRender={(item, dom) => matchMenu(item.permissionRequired) && (
          <Link to={item.path || '/'}
          >
            {dom}
          </Link>
        )}
        rightContentRender={() => (
          <Header />
        )}
        {...settings}
      >
          <div style={{height: '100%'}}>
              {children}
          </div>
      </ProLayout>
  );
};