import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'workshop',
    label: i18n('entities.responses.fields.workshop'),
    schema: schemas.relationToOne(
      i18n('entities.responses.fields.workshop'),
      {},
    ),
  },
  {
    name: 'requirement',
    label: i18n('entities.responses.fields.requirement'),
    schema: schemas.relationToOne(
      i18n('entities.responses.fields.requirement'),
      {},
    ),
  },
  {
    name: 'project',
    label: i18n('entities.responses.fields.project'),
    schema: schemas.relationToOne(
      i18n('entities.responses.fields.project'),
      {},
    ),
  },
  {
    name: 'score',
    label: i18n('entities.responses.fields.score'),
    schema: schemas.integer(
      i18n('entities.responses.fields.score'),
      {
        "min": 0,
        "max": 5
      },
    ),
  },
];