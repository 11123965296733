import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'name',
    label: i18n('entities.project.fields.name'),
    schema: schemas.string(
      i18n('entities.project.fields.name'),
      {},
    ),
  },
  {
    name: 'requirements',
    label: i18n('entities.project.fields.requirements'),
    schema: schemas.relationToMany(
      i18n('entities.project.fields.requirements'),
      {},
    ),
  },
  {
    name: 'lead',
    label: i18n('entities.project.fields.lead'),
    schema: schemas.relationToOne(
      i18n('entities.project.fields.lead'),
      {},
    ),
  },
  {
    name: 'team',
    label: i18n('entities.project.fields.team'),
    schema: schemas.relationToMany(
      i18n('entities.project.fields.team'),
      {},
    ),
  },
  {
    name: 'workshops',
    label: i18n('entities.project.fields.workshops'),
    schema: schemas.relationToMany(
      i18n('entities.project.fields.workshops'),
      {},
    ),
  },
  {
    name: 'responses',
    label: i18n('entities.project.fields.responses'),
    schema: schemas.relationToMany(
      i18n('entities.project.fields.responses'),
      {},
    ),
  },
  {
    name: 'businessUnits',
    label: i18n('entities.project.fields.businessUnits'),
    schema: schemas.relationToMany(
      i18n('entities.project.fields.businessUnits'),
      {},
    ),
  },
  {
    name: 'tags',
    label: i18n('entities.project.fields.tags'),
    schema: schemas.relationToMany(
      i18n('entities.project.fields.tags'),
      {},
    ),
  },
  {
    name: 'status',
    label: i18n('entities.project.fields.status'),
    schema: schemas.integer(
      i18n('entities.project.fields.status'),
      {},
    ),
  },
  {
    name: 'key',
    label: i18n('entities.project.fields.key'),
    schema: schemas.string(
      i18n('entities.project.fields.key'),
      {},
    ),
  },
  {
    name: 'shared',
    label: i18n('entities.project.fields.shared'),
    schema: schemas.boolean(
      i18n('entities.project.fields.shared'),
      {},
    ),
  },
  {
    name: 'description',
    label: i18n('entities.project.fields.description'),
    schema: schemas.string(
      i18n('entities.project.fields.description'),
      {},
    ),
  },
];