import { replace } from "lodash";

export const generateKey = (str: string) => {
  console.log(str)
  console.log(str.trim().split(" "))
    if(str.trim().split(" ").length < 3) {
      return str.replace(/ /g,'').replace(/[^a-z0-9]/gi,'').substring(0, 3).toUpperCase()
    } 
    // eslint-disable-next-line
    return str.split(/\s/).slice(0,8).reduce((response, word) => (response += word.slice(0, 1)), '').toUpperCase();
  };
  
  export const generateKeyNoNumeric = (str: string) => {
    console.log(str)
    console.log(str.trim().split(" "))
      if(str.trim().split(" ").length < 3) {
        return str.replace(/ /g,'').replace(/[0-9]/g, '').replace(/[^a-z]/gi,'').substring(0, 3).toUpperCase()
      } 
      // eslint-disable-next-line
      return str.replace(/[0-9]/g, '').split(/\s/).slice(0,8).reduce((response, word) => (response += word.slice(0, 1)), '').toUpperCase().replace(/\s/g, '');
    };
  
  export const stripAllButAlpha = (str: string) => {
    return str?.replace(/ /g,'').replace(/[^a-z]/gi,'').replace(/\s/g, '')
  } 