import Message from "src/view/shared/message";
import socketActions from "./socketActions";

const initialData = {
  loading: false,
  helloCount: 0
};

export default function reducer(state = initialData, action){
    switch(action.type){
      case 'message':
        Message.success(action.data)
        return Object.assign({}, {message:action.data});
      case socketActions.SAY_HELLO:
        return {
          ...state,
          loading: true,
        };
      case socketActions.GET_HELLO_COUNT_RECEIVE:
        console.log(action)
        return {
          ...state,
          helloCount: action.data.count,
          loading: false,
        };
      case socketActions.SAY_HELLO_SUCCESS:
        return {
          ...state,
          loading: false,
        };
      case socketActions.SAY_HELLO_ERROR:
        return {
          ...state,
          loading: false,
        };
      default:
        return state;
    }
  }

